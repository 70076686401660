import { FC } from 'react';

import { GridLayout } from '@hh.ru/magritte-ui';

import BannerLoginLanding from 'src/components/BannerLogin/BannerLoginLanding';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import PageWrapper from 'src/components/PageWrapper';

import ArticlesSection from 'src/pages/Index/components/ArticlesSection';
import CareerPlanningSection from 'src/pages/Index/components/CareerPlanningSection';
import CoursesSection from 'src/pages/Index/components/CoursesSection';
import MainSection from 'src/pages/Index/components/MainSection';
import SpinningStar from 'src/pages/Index/components/SpinningStar';

import styles from 'src/pages/Index/styles.less';

const PageLayout: FC = () => (
    <PageWrapper>
        <div className={styles.pageWrapper}>
            <div className={styles.pageContainer}>
                <Header absolute />
                <main className={styles.mainContainer}>
                    <MainSection />
                    <GridLayout>
                        <CoursesSection />
                    </GridLayout>
                    <div className={styles.subContainer}>
                        <CareerPlanningSection />
                        <div className={styles.loginBannerWrapper}>
                            <BannerLoginLanding />
                        </div>
                        <GridLayout>
                            <ArticlesSection />
                        </GridLayout>
                    </div>
                </main>
                <Footer />
            </div>
            <SpinningStar />
        </div>
    </PageWrapper>
);

export default PageLayout;
