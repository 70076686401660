import { EdProgramDto } from 'src/types/EdProgram';
import { SalaryDto } from 'src/types/common';
import { ProfessionDescriptionDto } from 'src/types/profession';
import { ProfileSkillDto } from 'src/types/profile';
import { TaskDto } from 'src/types/task';

export enum LKSectionState {
    Empty = 'empty',
    UpSkill = 'upskill',
    ReSkill = 'reskill',
}

export enum LKScrollAnchors {
    Mentors = 'anchor-mentors',
}

export interface PageLKState {
    currentSection: LKSectionState;
    coursesByPopularSkills: EdProgramDto[];
    coursesWithHighSalaryOneYear: EdProgramDto[];
    upskillCoursesBySkill: EdProgramDto[];
    upskillCoursesByProfession: EdProgramDto[];
    widgetTasks: TaskDto[];
    widgetSkills: {
        skills: ProfileSkillDto[];
        salary: SalaryDto;
    } | null;
    widgetSalaryByGrades: ProfessionDescriptionDto | null;
}
