import { FC } from 'react';

import { Title, VSpacing } from '@hh.ru/magritte-ui';

import ArticleCardContainer from 'src/components/Article/components/ArticleCardContainer';
import ArticleMeta from 'src/components/Article/components/ArticleMeta';
import { ArticleCommonDto } from 'src/types/article';

import styles from './styles.less';

interface Props {
    article: ArticleCommonDto;
    showBorder: boolean;
}

const ArticleCard: FC<Props> = ({ article, showBorder }) => (
    <ArticleCardContainer article={article} showBorder={showBorder} containerClasses={styles.container}>
        <ArticleMeta views={article.views} publishedAt={article.publishedAt} />
        <VSpacing default={24} />
        <Title size="small" Element="h4" maxLines={4}>
            {article.title}
        </Title>
    </ArticleCardContainer>
);

export default ArticleCard;
