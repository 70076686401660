import { FC } from 'react';

import { EyeOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import { ArticleCommonDto } from 'src/types/article';
import { NON_BREAKING_SPACE } from 'src/utils/characters';
import { formatCommonDateToHTMLDateTime, formatCommonDateToHuman } from 'src/utils/dates/format';

import styles from './styles.less';

type Props = Pick<ArticleCommonDto, 'views' | 'publishedAt'>;

const ArticleMeta: FC<Props> = ({ views, publishedAt }) => (
    <div className={styles.container}>
        <time dateTime={formatCommonDateToHTMLDateTime(publishedAt)}>{formatCommonDateToHuman(publishedAt)}</time>
        <p className={styles.views}>
            <EyeOutlinedSize16 initial="secondary" />
            {NON_BREAKING_SPACE}
            {views}
        </p>
    </div>
);

export default ArticleMeta;
