import { format } from 'date-fns-tz';
import isSameDay from 'date-fns/isSameDay';
import isValid from 'date-fns/isValid';
import isWithinInterval from 'date-fns/isWithinInterval';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';

import { CommonDateString, HTMLDateString, HumanDateString } from 'src/utils/dates/types';

export const DATE_FORMAT_HUMAN = 'dd.MM.yyyy';
export const DATE_FORMAT_HTML = 'yyyy-MM-dd';
export const DATE_FORMAT_COMMON = "yyyy-MM-dd'T'HH:mm:ss'Z'";

/**
 * `Date` => `yyyy-MM-dd'T'HH:mm:ss'Z'`
 */
export const formatCommonDate = (date: Date | null): string | null => {
    if (!date) {
        return null;
    }
    return format(date, DATE_FORMAT_COMMON, {
        timeZone: 'UTC',
    });
};

/**
 * `yyyy-MM-dd'T'HH:mm:ss'Z'` => `Date`
 */
export const parseCommonDate = (dateString: CommonDateString | null | undefined): Date | null => {
    if (!dateString) {
        return null;
    }

    const parsed = parseISO(dateString);

    return isValid(parsed) ? parsed : null;
};

/**
 * `yyyy-MM-dd'T'HH:mm:ss'Z'` => `'dd.MM.yyyy'`
 */
export const formatCommonDateToHuman = (dateString: CommonDateString | null): HumanDateString | null => {
    const date = parseCommonDate(dateString);
    if (!date) {
        return null;
    }
    return isValid(date) ? format(date, DATE_FORMAT_HUMAN) : null;
};

/**
 * `yyyy-MM-dd'T'HH:mm:ss'Z'` => `'yyyy-MM-dd'`
 */
export const formatCommonDateToHTMLDateTime = (dateString: CommonDateString | null): HTMLDateString => {
    const date = parseCommonDate(dateString);
    if (!date) {
        return '';
    }
    return isValid(date) ? format(date, DATE_FORMAT_HTML) : '';
};

/**
 * `'dd.MM.yyyy'` => `Date`
 */
export const parseCommonDateFromHuman = (dateString: HumanDateString | null): Date | null => {
    if (!dateString) {
        return null;
    }

    const parsed = parse(dateString, DATE_FORMAT_HUMAN, new Date());

    if (!parsed) {
        return null;
    }

    return isValid(parsed) ? parsed : null;
};

/**
 * `'dd.MM.yyyy'` => `yyyy-MM-dd'T'HH:mm:ss'Z'`
 */
export const parseCommonDateStringFromHuman = (dateString: HumanDateString | null): CommonDateString | null => {
    if (!dateString) {
        return null;
    }

    const parsed = parse(dateString, DATE_FORMAT_HUMAN, new Date());

    if (!parsed) {
        return null;
    }

    return isValid(parsed) ? formatCommonDate(parsed) : null;
};

export const isBetweenInclusive = (date: Date, start: Date, end: Date): boolean => {
    return isWithinInterval(date, { start, end }) || isSameDay(date, start) || isSameDay(date, end);
};
