import { FC, ReactNode } from 'react';

import { useElementShown } from '@hh.ru/analytics-js';
import articleCardButtonClick from '@hh.ru/analytics-js-events/build/career_platform/articles/article_card_button_click';
import articleCardElementShown from '@hh.ru/analytics-js-events/build/career_platform/articles/article_card_element_shown';
import { Card } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import { getArticleRouteHref } from 'src/app/routesUtils';
import { ArticleCommonDto } from 'src/types/article';
import getDataQa from 'src/utils/getDataQa';

interface Props {
    article: ArticleCommonDto;
    showBorder?: boolean;
    padding?: 16 | 0;
    borderRadius?: 24 | 0;
    disableHover?: boolean;
    containerClasses?: string;
    children: ReactNode;
}

const ArticleCardContainer: FC<Props> = ({
    article,
    showBorder = false,
    padding = 16,
    borderRadius = 24,
    disableHover = false,
    containerClasses,
    children,
}) => {
    const ref = useElementShown(articleCardElementShown, { articleId: article.id, articleTitle: article.title });
    const handleClick = () => articleCardButtonClick({ articleId: article.id, articleTitle: article.title });

    return (
        <article
            className={containerClasses}
            ref={ref}
            data-qa={getDataQa('article-card', article.id)}
            onClick={handleClick}
        >
            <Card
                padding={padding}
                borderRadius={borderRadius}
                stretched
                verticalStretched
                actionCard
                showBorder={showBorder}
                hoverStyle={disableHover ? undefined : 'neutral'}
                Element={SPALink}
                to={getArticleRouteHref(article.slug)}
                isSeoLink
            >
                {children}
            </Card>
        </article>
    );
};

export default ArticleCardContainer;
