import { useEffect, useRef } from 'react';

import careerMainButtonClick from '@hh.ru/analytics-js-events/build/career_platform/main/career_main_button_click';
import careerPlatformMainScreenShown from '@hh.ru/analytics-js-events/build/career_platform/main/career_platform_main_screen_shown';
import { translation, format, TranslatedComponent, numberFormatter } from '@hh.ru/front-static-app';
import { Button, Card, GridLayout, Text } from '@hh.ru/magritte-ui';
import { ArrowDownOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import { LOGIN_ROUTE } from 'src/app/routesUtils';
import { EdProgramFilterParams } from 'src/app/routesUtils/edProgramFilterParams';
import TitleSecondary, { TitleSecondarySize } from 'src/components/TitleSecondary';
import { COURSES_SECTION_ID } from 'src/pages/Index/constants';
import getDataQa from 'src/utils/getDataQa';
import pluralize from 'src/utils/pluralize';

import styles from './styles.less';

const TrlKeys = {
    title: 'career-platform.landing.main-section.title',
    text: 'career-platform.landing.main-section.text',
    seeCourses: 'career-platform.landing.main-section.seeCourses',
    authAndTry: 'career-platform.landing.main-section.authAndTry',
    statistics: {
        vacancies: 'career-platform.landing.main-section.statistics.vacancies',
        professions: 'career-platform.landing.main-section.statistics.professionsReady',
        courses: 'career-platform.landing.main-section.statistics.courses',
    },
    profession: {
        one: 'career-platform.pluralize.profession.one',
        some: 'career-platform.pluralize.profession.some',
        many: 'career-platform.pluralize.profession.many',
    },
    course: {
        one: 'career-platform.pluralize.course.one',
        some: 'career-platform.pluralize.course.some',
        many: 'career-platform.pluralize.course.many',
    },
    available: {
        one: 'career-platform.landing.main-section.pluralize.available.one',
        some: 'career-platform.landing.main-section.pluralize.available.some',
        many: 'career-platform.landing.main-section.pluralize.available.many',
    },
};

const DATA_QA_BLOCK = 'main-section';

const MainSection: TranslatedComponent = ({ trls }) => {
    const { vacancies, courses, professions } = useSelectorNonNullable((store) => store.landing.counters);

    const backgroundRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        careerPlatformMainScreenShown({ hhtmSourceLabel: 'career_platform_main' });
    }, []);

    const handleScrollClick = () => {
        const coursesSection = document.querySelector<HTMLElement>(`#${COURSES_SECTION_ID}`);
        if (!coursesSection) {
            return;
        }
        window.scrollTo({ behavior: 'smooth', top: coursesSection.offsetTop });
        careerMainButtonClick({ buttonName: 'down_arrow_click' });
    };

    return (
        <div className={styles.wrapper} data-qa={getDataQa(DATA_QA_BLOCK)}>
            <GridLayout>
                <section className={styles.container}>
                    <div className={styles.background} ref={backgroundRef} />
                    <div className={styles.mainContent}>
                        <h1 className={styles.title} data-qa={getDataQa(DATA_QA_BLOCK, 'title')}>
                            {trls[TrlKeys.title]}
                        </h1>
                        <div className={styles.textWrapper}>
                            <p className={styles.text} data-qa={getDataQa(DATA_QA_BLOCK, 'description')}>
                                {trls[TrlKeys.text]}
                            </p>
                        </div>
                        <div className={styles.buttonRow}>
                            <div className={styles.buttonCol}>
                                <Button
                                    mode="primary"
                                    size="large"
                                    Element={SPALink}
                                    to={LOGIN_ROUTE}
                                    isSeoLink
                                    onClick={() => careerMainButtonClick({ buttonName: 'login_and_try' })}
                                    data-qa={getDataQa(DATA_QA_BLOCK, 'login')}
                                >
                                    {trls[TrlKeys.authAndTry]}
                                </Button>
                                <Button
                                    mode="secondary"
                                    size="large"
                                    Element={SPALink}
                                    to={EdProgramFilterParams.getEdProgramsHref()}
                                    isSeoLink
                                    onClick={() => careerMainButtonClick({ buttonName: 'suitable_courses' })}
                                    data-qa={getDataQa(DATA_QA_BLOCK, 'show-all-courses')}
                                >
                                    {trls[TrlKeys.seeCourses]}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Card actionCard onClick={handleScrollClick} borderRadius={16} padding={16} style="neutral">
                            <div className={styles.scrollToCoursesItemContent}>
                                <ArrowDownOutlinedSize24 initial="primary" />
                            </div>
                        </Card>
                        <ul className={styles.counterList}>
                            <li className={styles.counterItem}>
                                <TitleSecondary size={TitleSecondarySize.Size3} Element="h3">
                                    {numberFormatter.format(`${professions}`)}
                                </TitleSecondary>
                                <div className={styles.counterItemText}>
                                    <Text typography="paragraph-2-regular">
                                        {format(trls[TrlKeys.statistics.professions], {
                                            '{0}': pluralize({
                                                count: professions,
                                                one: trls[TrlKeys.profession.one],
                                                some: trls[TrlKeys.profession.some],
                                                many: trls[TrlKeys.profession.many],
                                            }),
                                            '{1}': pluralize({
                                                count: professions,
                                                one: trls[TrlKeys.available.one],
                                                some: trls[TrlKeys.available.some],
                                                many: trls[TrlKeys.available.many],
                                            }),
                                        })}
                                    </Text>
                                </div>
                            </li>
                            <li className={styles.counterItem}>
                                <TitleSecondary size={TitleSecondarySize.Size3} Element="h3">
                                    {numberFormatter.format(`${vacancies}`)}
                                </TitleSecondary>
                                <div className={styles.counterItemText}>
                                    <Text typography="paragraph-2-regular">{trls[TrlKeys.statistics.vacancies]}</Text>
                                </div>
                            </li>
                            <li className={styles.counterItem}>
                                <TitleSecondary size={TitleSecondarySize.Size3} Element="h3">
                                    {numberFormatter.format(`${courses}`)}
                                </TitleSecondary>
                                <div className={styles.counterItemText}>
                                    <Text typography="paragraph-2-regular">
                                        {format(trls[TrlKeys.statistics.courses], {
                                            '{0}': pluralize({
                                                count: courses,
                                                one: trls[TrlKeys.course.one],
                                                some: trls[TrlKeys.course.some],
                                                many: trls[TrlKeys.course.many],
                                            }),
                                        })}
                                    </Text>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
            </GridLayout>
        </div>
    );
};

export default translation(MainSection);
