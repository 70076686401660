import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import styles from './styles.less';

export enum TitleSecondarySize {
    Size1 = '1',
    Size2 = '2',
    Size3 = '3',
    Size4 = '4',
    Size5 = '5',
}

export enum TitleSecondaryStyle {
    Primary = 'primary',
    Contrast = 'contrast',
}

export enum TitleSecondaryAlignment {
    Left = 'left',
    Center = 'center',
}

type Element = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'p';

interface Props {
    size: TitleSecondarySize;
    style?: TitleSecondaryStyle;
    alignment?: TitleSecondaryAlignment;
    Element?: Element;
    'data-qa'?: string;
    children: ReactNode;
}

const TitleSecondary: FC<Props> = ({
    size,
    style = TitleSecondaryStyle.Primary,
    alignment = TitleSecondaryAlignment.Left,
    Element = 'div',
    children,
    ...restProps
}) => (
    <Element
        className={classnames({
            [styles[`title-secondary_size-${size}`]]: true,
            [styles[`title-secondary_style-${style}`]]: true,
            [styles[`title-secondary_alignment-${alignment}`]]: true,
        })}
        {...restProps}
    >
        {children}
    </Element>
);

export default TitleSecondary;
