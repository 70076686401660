import { useCallback } from 'react';

import { useElementShown } from '@hh.ru/analytics-js';
import careerMainButtonClick from '@hh.ru/analytics-js-events/build/career_platform/main/career_main_button_click';
import careerMainCoursesElementShown from '@hh.ru/analytics-js-events/build/career_platform/main/career_main_courses_element_shown';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, Title, useBreakpoint } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import { EdProgramFilterParams } from 'src/app/routesUtils/edProgramFilterParams';
import CardEdProgram from 'src/components/CardEdProgram/CardEdProgram';
import { COURSES_SECTION_ID } from 'src/pages/Index/constants';
import getDataQa from 'src/utils/getDataQa';

import styles from './styles.less';

const TrlKeys = {
    title: 'career-platform.landing.courses-section.title',
    button: 'career-platform.landing.courses-section.button',
};

const DATA_QA_BLOCK = 'courses-section';

const CoursesSection: TranslatedComponent = ({ trls }) => {
    const { courses } = useSelectorNonNullable((state) => state.landing);

    const { isM, isMobile } = useBreakpoint();

    const sectionRef = useElementShown(careerMainCoursesElementShown);

    const getList = useCallback(() => {
        if (isMobile) {
            return courses.slice(0, 3);
        }
        if (isM) {
            return courses.slice(0, 6);
        }
        return courses;
    }, [courses, isM, isMobile]);

    return (
        <section ref={sectionRef} id={COURSES_SECTION_ID} data-qa={getDataQa(DATA_QA_BLOCK)}>
            <div className={styles.title}>
                <Title
                    size={isMobile ? 'medium' : 'large'}
                    alignment="center"
                    Element="h2"
                    dataQaTitle={getDataQa(DATA_QA_BLOCK, 'title')}
                >
                    {trls[TrlKeys.title]}
                </Title>
            </div>
            <div className={styles.list}>
                {getList().map((item) => (
                    <CardEdProgram key={item.id} edProgram={item} />
                ))}
            </div>
            <Button
                mode="secondary"
                size="large"
                stretched
                Element={SPALink}
                to={EdProgramFilterParams.getEdProgramsHref()}
                isSeoLink
                onClick={() => careerMainButtonClick({ buttonName: 'all_courses' })}
                data-qa={getDataQa(DATA_QA_BLOCK, 'show-all-courses')}
            >
                {trls[TrlKeys.button]}
            </Button>
        </section>
    );
};

export default translation(CoursesSection);
